<template>
  <section id="Modelo8x8" class="container-fluid __modelo8x8-container" data-aos="fade-up">
    <div class="row">
      <div class="col-sm text-center">
        <h2><b>{{ data.title }}</b></h2>
        <h3>
          {{ data.subtitle_1 }}
        </h3>

        <div class="mx-md-auto my-4">
          <div class="row justify-content-center fw-bold">
            <div class="col-12 col-sm-3 my-2 m-sm-0 ">
              <h6 class="boton-verde w-100">{{ data.green_button }}</h6>
            </div>
            <div class="col-12 col-sm-3 my-2 m-sm-0">
              <h6 class="boton-celeste w-100">{{ data.blue_button }}</h6>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <h3 class=" mb-5 subtitle">
            {{ data.subtitle_2 }}
          </h3>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row m-0 section-table">
        <div class="col-12 col-lg-6 p-0" v-for="column in data.table" :key="column.id">
          <h3
            id="tablaEstrategiaTitulo"
            class="text-center my-0"
            :class="{'container-titulo-estrategia':  column.color === 'green', 'container-titulo-ejecucion': column.color === 'blue'}"
          >
            {{ column.title }}
          </h3>
          <div class="row container-informacion-tabla w-100 m-0">
            <div class="col-3"
                 :class="{'th-verde': column.color === 'green', 'th-celeste': column.color === 'blue'}"
                 v-for="cellTitle in column.th" :key="cellTitle.id">
              <p class="mb-0 fw-bold">{{ cellTitle.text }}</p>
            </div>

            <div class="col-3 "
                 :class="{'tr-verde': column.color === 'green', 'tr-celeste': column.color === 'blue'}"
                 v-for="cell in column.tr" :key="cell.id">
              <p class="m-0" v-html="cell.text"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import {ConsultaSeccion8x8} from "@/helpers/contenido.js";
import store from '../../store';

export default {
  name: "Modelo8x8",
  data() {
    return {

      data: {
        title: 'Modelo Wetforest 8x8',
        subtitle_1: 'Wetforest ha desarrollado un exitoso modelo 8X8. Framework que cubre 8 pilares.',
        green_button: '4 en el ámbito estratégico',
        blue_button: '4 en el de ejecución',
        subtitle_2: 'y sus respectivos 8 ejes que cubren los elementos fundamentales de gestión de una empresa.',
        table: [
          {
            id: 1,
            color: 'green',
            title: 'ESTRATEGIA',
            th: [
              {id: 11, text: 'Alineamiento Estratégico'},
              {id: 21, text: 'Organización',},
              {id: 31, text: 'Capital Humano'},
              {id: 41, text: 'Inversión'}
            ],
            tr: [
              {id: 3123121, text: 'Propósito Visión Misión Valores'},
              {id: 32, text: 'Estructura Accionaria'},
              {id: 33, text: 'Cultura'},
              {id: 34, text: 'Ecosistema de Inversión'},

              {id: 35, text: 'Responsabilidad Social y Medioambiental'},
              {id: 36, text: 'Gobierno Corporativo'},
              {id: 37, text: 'Talento'},
              {id: 38, text: 'Accionistas'},

              {id: 39, text: 'Modelo de Negocio'},
              {id: 40, text: 'Dirección General'},
              {id: 4221, text: 'Selección y Retención'},
              {id: 42, text: 'Requerimiento de Inversión'},

              {id: 43, text: 'Estrategia'},
              {id: 44, text: 'Modelo de Gestión'},
              {id: 45, text: 'Liderazgo'},
              {id: 46, text: 'Plan Inversión y Financiamiento '},

              {id: 47, text: 'Objetivos'},
              {id: 48, text: 'Estructura Organizacional '},
              {id: 49, text: 'Engagement'},
              {id: 50, text: 'Capacidad de Levantar inversión '},

              {id: 51, text: 'Planes'},
              {id: 52, text: 'Roles y Responsabilidad '},
              {id: 53, text: 'Compensaciones'},
              {id: 54, text: 'Valorización'},

              {id: 55, text: 'Métricas KPI & OKR'},
              {id: 56, text: ' Coordinación Colaboración'},
              {id: 57, text: 'Administración del Desempeño'},
              {id: 58, text: 'Negociación'},

              {id: 59, text: 'Gestión de Riesgos '},
              {id: 60, text: 'Comunicaciones'},
              {id: 61, text: 'Desarrollo de Carrera'},
              {id: 62, text: 'Oportunidades de Crecimiento'},

            ]
          },
          {
            id: 2,
            color: 'blue',
            title: 'EJECUCIÓN',
            th: [
              {id: 112221, text: 'Finanzas'},
              {id: 2122221, text: 'Innovación',},
              {id: 32221, text: 'Operaciones'},
              {id: 412221, text: 'Comercial'}
            ],
            tr: [
              {id: 311, text: 'Presupuestos y Proyecciones'},
              {id: 312, text: 'Estrategia e Innovación'},
              {id: 313, text: 'Modelo de Operaciones'},
              {id: 314, text: 'Ecosistema del Negocio'},

              {id: 315, text: 'Informes Financieros'},
              {id: 316, text: ' Innovación Regular, Disruptiva y Reactiva'},
              {id: 317, text: 'Infraestructura de Producción'},
              {id: 318, text: 'Mercado Objetivo'},

              {id: 319, text: 'Contabilidad'},
              {id: 410, text: 'Oportunidades y Priorización'},
              {id: 411, text: 'Procesos'},
              {id: 412, text: 'Marketing y Comunicaciones'},

              {id: 413, text: 'Impuestos'},
              {id: 414, text: 'Gestión del Cambio y Medición'},
              {id: 415, text: 'Tecnología'},
              {id: 416, text: 'Oferta de Servicios y Productos '},

              {id: 417, text: 'Legal'},
              {id: 418, text: ' Inversión e Innovación Abierta '},
              {id: 419, text: 'Proyecto y Acciones'},
              {id: 510, text: 'Pronóstico de Ventas '},

              {id: 511, text: 'Gestión del Riesgo'},
              {id: 512, text: 'Procesos, Sistemas y Tecnología '},
              {id: 513, text: 'Servicio al Cliente'},
              {id: 514, text: 'Ventas'},

              {id: 515, text: 'Gestión de Recursos'},
              {id: 516, text: ' Organización para la Innovación'},
              {id: 517, text: 'Productos y Servicios'},
              {id: 518, text: 'Modelo de Ingresos'},

              {id: 519, text: 'Payroll y Leyes Sociales '},
              {id: 610, text: 'Cultura de Innovación y Cambio'},
              {id: 611, text: 'Calidad y Q&A'},
              {id: 612, text: 'Business Partners'},
            ]
          }
        ],
      },
    };
  },
  methods: {
    fetchData() {
      if (store.state.sections.wetforest8x8) {
        this.data = {...store.state.sections.wetforest8x8.components};
      }
    }

  },
  watch: {
    '$store.state.sections': function () {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

.__modelo8x8-container {
  h3 {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

.section-table {
  p {
    font-size: 12px !important;
  }


}

@media (min-width: 782px) {
  .section-table {
    p {
      font-size: 16px !important;
    }

    .tr-verde > p {
      font-size: 12.5px !important;
      font-weight: bold;
    }

    .tr-celeste > p {
      font-size: 12.5px !important;
      font-weight: bold;
    }

  }
}

@media (min-width: 1200px) {
  .subtitle {
    width: 60% !important;
  }
}

.boton-verde {
  background-color: #e5fcec;
  color: #14993c;
  justify-content: center;
  border-radius: 10px;
  padding: 0;
}

.boton-celeste {
  background-color: #e0f7ff;
  color: #189bcd;
  padding: 0;
  justify-content: center;
  border-radius: 10px;
}

.th-celeste {
  background-color: #7FC8E3FF;

  margin-top: 8px;
  font-size: 18px;
  font-family: Interbold;
  padding: 5px;
  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 8px solid #fff;
  /*Centrado de texto*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.th-verde {
  background-color: #73D18DFF;
  margin-top: 8px;
  font-size: 18px;
  padding: 5px;
  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 8px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container-titulo-estrategia {
  background-color: #27ba54;
  color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
  margin-right: 0.25rem;
}


.container-titulo-ejecucion {
  background-color: #40b9e6;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.25rem;
}

.tr-verde {
  min-height: 66px;
  background-color: #ABDEAEFF;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & p {
    font-size: 10px !important;
    font-weight: bold;
  }
}

.tr-celeste {
  background-color: #b6e4f5;

  min-height: 66px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & p {
    font-size: 10px !important;
    font-weight: bold;
  }
}

</style>
